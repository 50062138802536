import Header from "../header/Header";
import Chat from "../chat/Chat";
import styles from "../../CSS/Layout.module.css";
import { useContext, useEffect, useState } from "react";
import { CosmosDBStatus, ChatHistoryLoadingState } from "../../../api/models";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import { Loader } from "../../../components/CarbonAIChat/common/Common";
import UserProfileDetails from "../../UserProfile/UserProfileDetails";
const Layout = () => {
  const appStateContext = useContext(AppStateContext);
  const AUTH_ENABLED = appStateContext?.state.frontendSettings?.auth_enabled;
  const [showAuthMessage, setShowAuthMessage] = useState<boolean>(false);

  useEffect(() => {
    if (
      appStateContext?.state.isCosmosDBAvailable?.status ===
        CosmosDBStatus.NotWorking &&
      appStateContext.state.chatHistoryLoadingState ===
        ChatHistoryLoadingState.Fail
    ) {
      setShowAuthMessage(true);
    }
  }, [appStateContext?.state.isCosmosDBAvailable]);
  const getUserInfoList = async () => {
    if (!AUTH_ENABLED) {
      return;
    }
  };
  useEffect(() => {
    if (AUTH_ENABLED !== undefined) getUserInfoList();
  }, [AUTH_ENABLED]);

  return (
    <>
      {showAuthMessage ? (
        <Loader />
      ) : (
        <div className={`${styles.Layout} bgColorPrimary scrollHide`}>
          <Header />
          {appStateContext?.state.activeWindow === "profile" ? (
            <UserProfileDetails />
          ) : (
            <Chat />
          )}

          <footer>
            <p className={styles.footerText}>
              CarbonAI is pretty smart, but like all of us, it can make
              mistakes. It's always a good idea to double-check important
              information!
            </p>
          </footer>
        </div>
      )}
    </>
  );
};

export default Layout;
