import React, { useEffect, useContext, useReducer } from "react";
import {
  Modal,
  PrimaryButton,
  TextField,
  Stack,
  IModalStyles,
} from "@fluentui/react";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import { shareModal } from "../../../constants/constants";
import { shareModalAPI } from "../../../api/api";
import { Loader } from "../common/Common";
import styles from "./ShareChatModal.module.css";
import { ShareModal } from "../../../api";

// Modal styles
const modalStyles: Partial<IModalStyles> = {
  main: {
    minWidth: 450,
    maxWidth: 450,
    padding: 20,
    borderRadius: 8,
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    margin: "10px",
  },
};

// Initial state for the reducer
const initialState = {
  loading: false,
  shareUrl: "",
  buttonState: shareModal.create,
  error: false,
  disabledAction: false,
};

// Action types for the reducer
const actionTypes = {
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
  SET_BUTTON_STATE: "SET_BUTTON_STATE",
  SET_SHARE_URL: "SET_SHARE_URL",
  SET_DISABLED_ACTION: "SET_DISABLED_ACTION",
  RESET_STATE: "RESET_STATE",
};

// Reducer function to handle state transitions
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case actionTypes.SET_ERROR:
      return { ...state, error: action.payload };
    case actionTypes.SET_BUTTON_STATE:
      return { ...state, buttonState: action.payload };
    case actionTypes.SET_SHARE_URL:
      return { ...state, shareUrl: action.payload };
    case actionTypes.SET_DISABLED_ACTION:
      return { ...state, disabledAction: action.payload };
    case actionTypes.RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

const ShareChatModal: React.FC = () => {
  const appStateContext = useContext(AppStateContext);

  // UseReducer hook to handle all state logic
  const [state, dispatch] = useReducer(reducer, initialState);

  // Load API Data when popup opens
  useEffect(() => {
    if (appStateContext?.state.isShareOpen) {
      dispatch({ type: actionTypes.RESET_STATE });
      fetchPopupData();
    }
  }, [appStateContext?.state.isShareOpen]);

  // Fetch data when the modal opens
  const fetchPopupData = async () => {
    dispatch({ type: actionTypes.SET_LOADING, payload: true });
    dispatch({ type: actionTypes.SET_ERROR, payload: false });
    try {
      const response = await shareModalAPI({
        conversation_id: appStateContext?.state.currentChat?.id,
        operation: "getstatus",
      });
      const jsonData = await response.json();
      dispatch({
        type: actionTypes.SET_BUTTON_STATE,
        payload: jsonData.exist ? shareModal.update : shareModal.create,
      });
      if (jsonData.exist)
        dispatch({
          type: actionTypes.SET_SHARE_URL,
          payload: jsonData.new_conversation_id,
        });
    } catch (error) {
      console.error("Error fetching data", error);
      dispatch({ type: actionTypes.SET_ERROR, payload: true });
    } finally {
      dispatch({ type: actionTypes.SET_LOADING, payload: false });
    }
  };

  // Handle creating or updating the link
  const handleButtonClick = async () => {
    dispatch({ type: actionTypes.SET_DISABLED_ACTION, payload: true });

    try {
      let payload: ShareModal = {
        conversation_id: appStateContext?.state.currentChat?.id,
        operation:
          state.buttonState === shareModal.create ? "create" : "update", // Adjust the operation as needed
      };
      if (state.buttonState === shareModal.update) {
        payload.new_conversation_id = state.shareUrl;
      }
      const response = await shareModalAPI(payload);
      const jsonData = await response.json();
      const updatedShareUrl = jsonData.new_conversation_id;
      dispatch({ type: actionTypes.SET_SHARE_URL, payload: updatedShareUrl });
      dispatch({
        type: actionTypes.SET_BUTTON_STATE,
        payload: shareModal.copy,
      });
    } catch (error) {
      console.error("Error fetching data", error);
      dispatch({ type: actionTypes.SET_SHARE_URL, payload: "" });
      dispatch({
        type: actionTypes.SET_BUTTON_STATE,
        payload: shareModal.update,
      });
      dispatch({ type: actionTypes.SET_ERROR, payload: true });
    } finally {
      dispatch({ type: actionTypes.SET_DISABLED_ACTION, payload: false });
    }
  };

  // Handle copying the URL to clipboard
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}/share/${state.shareUrl}`
      );
      dispatch({
        type: actionTypes.SET_BUTTON_STATE,
        payload: shareModal.copied,
      });
    } catch (err) {
      console.error("Failed to copy to clipboard:", err);
      fallbackCopyToClipboard(
        `${window.location.origin}/share/${state.shareUrl}`
      );
    } finally {
      setTimeout(() => {
        dispatch({
          type: actionTypes.SET_BUTTON_STATE,
          payload: shareModal.copy,
        });
      }, 1000);
    }
  };

  // Fallback function to copy text using execCommand
  const fallbackCopyToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      const successful = document.execCommand("copy");
      dispatch({
        type: actionTypes.SET_BUTTON_STATE,
        payload: shareModal.copied,
      });
      const msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
    document.body.removeChild(textarea);
  };

  return (
    <Modal
      isOpen={appStateContext?.state.isShareOpen}
      onDismiss={() => appStateContext?.dispatch({ type: "TOGGLE_SHARE" })}
      isBlocking={false}
      styles={modalStyles}
    >
      <Stack>
        {/* Modal Header */}
        <Stack.Item className={styles.shareModalHeader}>
          <h5 className={styles.modalHeaderTitle}>Public Link to Share Chat</h5>
          <button
            type="button"
            onClick={() => appStateContext?.dispatch({ type: "TOGGLE_SHARE" })}
            aria-label="Close"
            className={styles.tickClose}
          >
            &times;
          </button>
        </Stack.Item>
        <Stack.Item className={styles.shareModalBody}>
          {state.loading ? (
            <Loader />
          ) : state.error ? (
            <div className={styles.errorContainer}>
              <p className="error">
                Sharing chat is not working right now. Please try again later!!!
              </p>
              <PrimaryButton
                className={styles.closeButton}
                onClick={() =>
                  appStateContext?.dispatch({ type: "TOGGLE_SHARE" })
                }
                text="Close"
              />
            </div>
          ) : (
            <>
              <p>Any messages you add after sharing will stay private.</p>
              <Stack className={styles.shareModalBodyContent}>
                <TextField
                  placeholder="Public link to chat"
                  value={
                    state.shareUrl.length > 0 &&
                    (state.buttonState === shareModal.copied ||
                      state.buttonState === shareModal.copy)
                      ? `${window.location.origin}/share/${state.shareUrl}`
                      : ""
                  }
                  readOnly
                  className={styles.urlTxt}
                />

                <PrimaryButton
                  disabled={state.disabledAction}
                  onClick={
                    state.buttonState === shareModal.copied ||
                    state.buttonState === shareModal.copy
                      ? handleCopy
                      : handleButtonClick
                  }
                  text={state.buttonState}
                  className={styles.actionBtn}
                />
              </Stack>
            </>
          )}
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default ShareChatModal;
