export const shareModal = {
  create: "Create Link",
  update: "Update Link",
  copy: "Copy Link",
  copied: "Link Copied",
};
export const dataSource = {
  doc: "This response is from your CarbonAI assistant, combining web-curated datasets, conversational understanding, and pre-trained data as needed.",
  db: "This response is from your CarbonAI assistant, using its conversational insight and custom Carbon Registries database.",
};
export const otherFeedbackValues = {
  placeholder: "Extra feedback can be added here (mandatory).",
  error: "For others , adding a feedback above is mandatory.",
  title: "Maximum 100 characters are allowed",
};
export const [ASSISTANT, TOOL, ERROR] = ["assistant", "tool", "error"];

export const ReportinghubDomain = "https://carbonai.eco/";

export const regex =
  /^(https?|ftp):\/\/(?:www\.)?([\da-z\.-]+)\.([a-z\.]{2,6})(:\d+)?(\/[\w \.-]*)*\/?(?:\?[\w&=%\.\+-]*)?(?:#[\w\-]*)?$/;
export const allowedTypes = [
  "application/pdf",
  "text/plain",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const upload = {
  invalidUrl: "Please enter a valid url.",
  invalidFile:
    "Please ensure file is of valid type and is not empty (of 0 KB).",
  uploadSuccess:
    "Your submission has been successfully uploaded. Further process will continue in background!!!",
  uploadError: "Error in Uploading!!!",
  uploadWrongFile:
    "The file you are trying to upload is not valid. Please upload one of .doc,.docx,.txt or .pdf",
  upload404:
    "We were unable to extract any data or text from the provided URL. Please check the URL for accuracy and ensure it contains extractable text or data. If you continue to experience issues, please try uploading a different URL or contact support for assistance.",
  uploadWarning:
    "There are some documents without source.Would you like to upload below document without the source URL? Please note that this is not recommended.",
  uploadInvalidFile:
    "The files you are trying to upload are not valid. Please upload one of .doc,.docx,.txt or .pdf",
  listInvalidFile: "Below files are invalid:-",
  fileError: "You can only upload up to 5 files.",
};

export const fileStatus = [
  "Processing...",
  "Rejected(duplicate) File.",
  "Rejected(invalid) File.",
  "Rejected(unrelated) File.",
  "Successfully Processed.",
];
